





























































































































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'
import TextDisplayCopy from '@/components/misc/TextDisplayCopy.vue'
import Spinner from '@/components/misc/Spinner.vue'
// import TorusGoogle from "@/components/Torus/TorusGoogle.vue";
import MnemonicDisplay from '@/components/misc/MnemonicDisplay.vue'
import CopyText from '@/components/misc/CopyText.vue'
import * as bip39 from 'bip39'

import VerifyMnemonic2 from '@/components/modals/VerifyMnemonic2.vue'
import MnemonicCopied from '@/components/CreateWalletWorkflow/MnemonicCopied.vue'
import ToS from '@/components/misc/ToS.vue'
import MnemonicPhrase from '@/js/wallets/MnemonicPhrase'

@Component({
    components: {
        ToS,
        CopyText,
        // RememberKey,
        TextDisplayCopy,
        MnemonicDisplay,
        Spinner,
        // TorusGoogle,
        VerifyMnemonic2,
        MnemonicCopied,
    },
})
export default class CreateWallet extends Vue {
    // TODO: We do not need to create keyPair, only mnemonic is sufficient
    isLoad: boolean = false
    keyPhrase: MnemonicPhrase | null = null
    isSecured: boolean = false
    isVerified: boolean = false

    get canVerify(): boolean {
        return this.isSecured ? true : false
    }

    get verificationColor() {
        return this.isVerified ? '#a9efbf' : '#F5F6FA'
    }

    createKey(): void {
        this.isSecured = false
        let mnemonic = bip39.generateMnemonic(256)
        this.keyPhrase = new MnemonicPhrase(mnemonic)
    }

    get canSubmit(): boolean {
        return true
    }
    verifyMnemonic() {
        // @ts-ignore
        this.$refs.verify.open()
    }

    complete() {
        this.isVerified = true
    }

    async access(): Promise<void> {
        if (!this.keyPhrase) return

        this.isLoad = true

        let parent = this

        setTimeout(async () => {
            await parent.$store.dispatch('accessWallet', this.keyPhrase!.getValue())
        }, 500)
    }
}
