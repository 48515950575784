















































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import { AvaNetwork } from '@/js/AvaNetwork'
import punycode from 'punycode'

@Component
export default class EditPage extends Vue {
    name = 'My Custom Network'
    url = ''
    networkId = 12345
    explorer_api: string | undefined = ''
    explorer_site: string | undefined = ''
    chainId = 'X'
    err = null
    err_url = ''

    @Prop() net!: AvaNetwork

    mounted() {
        let net = this.net

        this.name = net.name
        this.url = net.getFullURL()
        this.networkId = net.networkId
        this.explorer_api = net.explorerUrl
        this.explorer_site = net.explorerSiteUrl
    }

    cleanExplorerUrl() {
        // console.log(val);
        let url = this.explorer_api as string
        this.explorer_api = punycode.toASCII(url)
        // console.log(this.explorer_api);
    }

    cleanExplorerSite() {
        let url = this.explorer_site as string
        url = punycode.toASCII(url)
        this.explorer_site = url
    }

    checkUrl() {
        let err = ''
        let url = this.url
        // protect against homograph attack: https://hethical.io/homograph-attack-using-internationalized-domain-name/
        url = punycode.toASCII(url)
        this.url = url

        // must contain http / https prefix
        if (url.substr(0, 7) !== 'http://' && url.substr(0, 8) !== 'https://') {
            this.err_url = 'URLs require the appropriate HTTP/HTTPS prefix.'
            return false
        }

        let split = url.split('://')
        let rest = split[1]

        // must have base ip
        if (rest.length === 0) {
            this.err_url = 'Invalid URL.'
            return false
        }

        // Must have port
        if (!rest.includes(':')) {
            this.err_url = 'You must specify the port of the url.'
            return false
        }
        // Port must be number

        let urlSplit = rest.split(':')
        if (urlSplit.length === 0) {
            this.err_url = 'Invalid port.'
            return false
        }

        let port = parseInt(urlSplit[1])

        if (isNaN(port)) {
            this.err_url = 'Invalid port.'
            return false
        }

        this.err_url = ''
        return true
    }
    errCheck() {
        let err = null

        // check for HTTP HTTPS on url
        let url = this.url

        if (url.substr(0, 7) !== 'http://' && url.substr(0, 8) !== 'https://') {
            err = 'URLs require the appropriate HTTP/HTTPS prefix.'
        }

        if (!this.name) err = 'You must give the network a name.'
        else if (!this.url) err = 'You must set the URL.'
        else if (!this.chainId) err = 'You must set the chain id.'
        else if (!this.networkId) err = 'You must set the network id.'

        return err
    }
    deleteNetwork() {
        this.$emit('delete')
    }
    async saveNetwork() {
        let net = this.net
        net.name = this.name
        net.updateURL(this.url)
        net.explorerUrl = this.explorer_api
        net.explorerSiteUrl = this.explorer_site
        net.networkId = this.networkId

        await this.$store.dispatch('Network/save')

        this.$store.dispatch('Notifications/add', {
            title: 'Changes Saved',
            message: 'Network settings updated.',
        })

        this.$emit('success')
    }
}
// export default {
//     data(){
//         return {
//             name: "My Custom Network",
//             url: '',
//             networkId: 12345,
//             explorer_api: '',
//             chainId: 'X',
//             err: null,
//             err_url: '',
//         }
//     },
//     props: {
//         net: {
//             type: AvaNetwork,
//             required: true
//         }
//     },
//     mounted() {
//         let net = this.net;
//
//         this.name = net.name;
//         this.url = net.getFullURL();
//         this.networkId = net.networkId;
//     },
//     methods:{
//         cleanExplorerUrl(){
//             // console.log(val);
//             let url = this.explorer_api;
//             this.explorer_api = punycode.toASCII(url);
//             // console.log(this.explorer_api);
//         },
//         checkUrl(){
//             let err = '';
//             let url = this.url;
//             // protect against homograph attack: https://hethical.io/homograph-attack-using-internationalized-domain-name/
//             url = punycode.toASCII(url);
//             this.url = url;
//
//             // must contain http / https prefix
//             if(url.substr(0,7) !== 'http://' && url.substr(0,8) !== 'https://'){
//                 this.err_url = "URLs require the appropriate HTTP/HTTPS prefix."
//                 return false;
//             }
//
//             let split = url.split('://');
//             let rest = split[1];
//
//             // must have base ip
//             if(rest.length===0){
//                 this.err_url = "Invalid URL.";
//                 return false;
//             }
//
//             // Must have port
//             if(!rest.includes(':')){
//                 this.err_url = "You must specify the port of the url.";
//                 return false;
//             }
//
//             let port = rest.split(':')[1];
//
//             // Port must be number
//             if(isNaN(port) || port.length===0){
//                 this.err_url = "Invalid port.";
//                 return false;
//             }
//
//             this.err_url = '';
//             return true;
//
//         },
//         errCheck(){
//             let err = null;
//
//             // check for HTTP HTTPS on url
//             let url = this.url;
//
//
//             if(url.substr(0,7) !== 'http://' && url.substr(0,8) !== 'https://'){
//                 err = "URLs require the appropriate HTTP/HTTPS prefix."
//             }
//
//             if(!this.name) err = "You must give the network a name.";
//             else if(!this.url) err = 'You must set the URL.';
//             else if(!this.chainId) err = 'You must set the chain id.';
//             else if(!this.networkId) err = 'You must set the network id.';
//
//
//             return err;
//         },
//         deleteNetwork(){
//             this.$emit('delete');
//         },
//         saveNetwork(){
//             let net = this.net;
//             net.name = this.name;
//             net.updateURL(this.url);
//             net.networkId =  this.networkId;
//             net.chainId =  this.chainId;
//
//             this.$parent.page = 'list';
//         },
//     }
// }
